import React, { useRef } from "react"

import styles from "../rethinking.module.scss"

const RedCarousel = () => {
  const firstSwiper = [
    {
      id: 1,
      text:
        "I felt energized and inspired recently when our company was pursuing training alternatives and I was allowed to investigate and pursue offerings of my own choosing and then bring back my findings to leadership and the team for further discussion.",
    },
    {
      id: 2,
      text:
        "My boss gave me the chance to set my own calendar and complete my work how I wanted. As long as the work was done well and on time, she was happy.",
    },
    {
      id: 3,
      text:
        "The ability not to be micromanaged and make decisions somewhat on my own. To also have the freedom to have a hybrid work schedule.",
    },
    {
      id: 4,
      text:
        "Working on projects with an intense deadline but having a team that is largely unavailable or unresponsive. You feel like you're talking to yourself.",
    },
    {
      id: 5,
      text:
        "I felt energized and inspired recently when our company was pursuing training alternatives and I was allowed to investigate and pursue offerings of my own choosing and then bring back my findings to leadership and the team for further discussion.",
    },
    {
      id: 6,
      text:
        "My boss gave me the chance to set my own calendar and complete my work how I wanted. As long as the work was done well and on time, she was happy.",
    },
    {
      id: 7,
      text:
        "The ability not to be micromanaged and make decisions somewhat on my own. To also have the freedom to have a hybrid work schedule.",
    },
    {
      id: 8,
      text:
        "Working on projects with an intense deadline but having a team that is largely unavailable or unresponsive. You feel like you're talking to yourself.",
    },
  ]
  const secondSwiper = [
    {
      id: 1,
      text:
        "The most stressful part of my week is working by myself most of the time. It's lonely. Nobody to bounce ideas off. Everyone is busy. Nobody else does what I do.",
    },
    {
      id: 2,
      text:
        "I feel most optimistic when I experience “we're all in this together” moments with my teams... It allows me to pivot from feeling like the weight of a project phase is solely on my shoulders to remembering that we work in teams for a reason.",
    },
    {
      id: 3,
      text:
        "Having heads-down solo time to work, think, and tinker... alongside occasional moments to share, talk, and celebrate. When these two things feel somewhat balanced I find a lot of joy in my days.",
    },
    {
      id: 4,
      text:
        "The unknowns are less daunting when you have people you trust available to lean on in difficult or trying times. I feel less alone and more confident in being able to overcome and see possibilities vs. feeling like it's all on you.",
    },
    {
      id: 5,
      text:
        "The most stressful part of my week is working by myself most of the time. It's lonely. Nobody to bounce ideas off. Everyone is busy. Nobody else does what I do.",
    },
    {
      id: 6,
      text:
        "I feel most optimistic when I experience “we're all in this together” moments with my teams... It allows me to pivot from feeling like the weight of a project phase is solely on my shoulders to remembering that we work in teams for a reason.",
    },
    {
      id: 7,
      text:
        "Having heads-down solo time to work, think, and tinker... alongside occasional moments to share, talk, and celebrate. When these two things feel somewhat balanced I find a lot of joy in my days.",
    },
    {
      id: 8,
      text:
        "The unknowns are less daunting when you have people you trust available to lean on in difficult or trying times. I feel less alone and more confident in being able to overcome and see possibilities vs. feeling like it's all on you.",
    },
  ]

  return (
    <>
      <div className={`${styles.slider} ${styles.sliderLeft}`}>
        <div className={styles.slideTrack}>
          {firstSwiper.map((item, i) => (
            <div
              className={styles.slide}
              style={{ height: "auto" }}
              key={`marquee-${item.id}`}
            >
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={`${styles.slider} ${styles.sliderRight}`}>
        <div className={styles.slideTrack}>
          {secondSwiper.map((item, i) => (
            <div
              className={styles.slide}
              style={{ height: "auto" }}
              key={`marquee-${item.id}`}
            >
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
export default RedCarousel
