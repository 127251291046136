import React, { useState, useEffect } from "react"
import ScrollOut from "scroll-out"

import styles from "../rethinking.module.scss"

import Image from "../../../../components/image"

import PhotoGif from "../../../../images/athena-04-resilience/06-rethinking/hero/photogif.gif"

import HeroPinkShape from "../../../../images/athena-04-resilience/06-rethinking/hero/pink-shape.png"
import HeroYellowShape from "../../../../images/athena-04-resilience/06-rethinking/hero/yello.png"
import HeroTexture from "../../../../images/athena-04-resilience/06-rethinking/hero/texture.gif"

const Hero = () => {
  let [heroFade, setHeroFade] = useState(true)
  useEffect(() => {
    setHeroFade(0)
    let timeoutId = setTimeout(() => {
      setHeroFade(1)
      timeoutId = setTimeout(() => {
        setHeroFade(2)
        timeoutId = setTimeout(() => {
          setHeroFade(3)
          timeoutId = setTimeout(() => {
            setHeroFade(4)
            timeoutId = setTimeout(() => {
              setHeroFade(5)
              timeoutId = setTimeout(() => {}, 1100)
            }, 500)
          }, 800)
        }, 650)
      }, 500)
    }, 200)
    return () => clearTimeout(timeoutId)
  }, [])

  let group = index => ({
    style: {
      transition: "opacity .6s ease-in-out",
      opacity: heroFade >= index ? 1 : 0.0125,
    },
  })

  useEffect(() => {
    let so = ScrollOut({
      threshold: 0.1,
      cssProps: {
        viewportY: true,
      },
    })

    return so.teardown
  }, [])

  return (
    <div className={`${styles.hero}`} data-scroll="">
      <div {...group(2)}>
        <Image className={styles.pinkShape} url={HeroPinkShape} />
      </div>
      <div {...group(3)}>
        <Image className={styles.yellowShape} url={HeroYellowShape} />
      </div>
      <div {...group(4)}>
        <Image className={styles.texture} url={HeroTexture} />
      </div>

      <div className={styles.heroText}>
        <div className={styles.issue} {...group(1)}>
          <p className="a-h-5">
            Issue 04 / Exploring Joy, Reimagining Resilience{" "}
          </p>
        </div>
        <div className={styles.title} {...group(2)}>
          <h1 className="a-h-display">Rethinking Resilience At Work</h1>
          <h3 style={{ marginTop: "20px" }} className="a-h-5">
            A Qualitative Research Study
          </h3>
        </div>
      </div>

      <div {...group(5)}>
        <Image className={styles.couple} url={PhotoGif} />
      </div>
    </div>
  )
}

export default Hero
