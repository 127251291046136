import React from "react"
import styles from "../rethinking.module.scss"

const PurpleCarousel = () => {
  const firstSwiper = [
    {
      id: 1,
      text:
        "I feel drained by days of back to back meetings that aren't about discovering anything or having a conversation but instead are just about logistics and keeping tabs on things... when those moments pile up it doesn't feel like it moves the work or any of us as individuals forward.",
    },
    {
      id: 2,
      text:
        "Constantly switching between the different headspaces necessary for the different projects and different tasks can be stressful. Sometimes I'm worried I'll forget about something important or miss something I ought not to.",
    },
    {
      id: 3,
      text:
        "I would absolutely love prioritizing play - but it's hard to figure out how to do so.  I think that having events that we can optionally attend as an organization would be really nice... journaling, group, meditating, yoga, or Zumba, or a fun game to play and that's facilitated by someone else.",
    },
    {
      id: 4,
      text:
        "If my employer chose to prioritize joy and playful exploration, that ideal experience for me would be to get more naps and take more breaks and work on my own time.",
    },
    {
      id: 5,
      text:
        "I feel drained by days of back to back meetings that aren't about discovering anything or having a conversation but instead are just about logistics and keeping tabs on things... when those moments pile up it doesn't feel like it moves the work or any of us as individuals forward.",
    },
    {
      id: 6,
      text:
        "Constantly switching between the different headspaces necessary for the different projects and different tasks can be stressful. Sometimes I'm worried I'll forget about something important or miss something I ought not to.",
    },
    {
      id: 7,
      text:
        "I would absolutely love prioritizing play - but it's hard to figure out how to do so.  I think that having events that we can optionally attend as an organization would be really nice... journaling, group, meditating, yoga, or Zumba, or a fun game to play and that's facilitated by someone else.",
    },
    {
      id: 8,
      text:
        "If my employer chose to prioritize joy and playful exploration, that ideal experience for me would be to get more naps and take more breaks and work on my own time.",
    },
  ]
  const secondSwiper = [
    {
      id: 1,
      text:
        "An ideal experience would involve having a bunch of indoor games that people can play when they need to take a break from work. I would definitely love to play table tennis or chess or have some books that I can read and take my mind off work.",
    },
    {
      id: 2,
      text:
        "I wish there were group play times—times when I can interact with my coworkers with a non-work task. This task could be something that helps open up our minds to innovation, but isn't directly related to a work task... I think it would infuse creativity into my team and make us feel less like hamsters on wheels.",
    },
    {
      id: 3,
      text:
        "I would spend more time playing with data, more time on whiteboards with close collaborators, more permission to do weird and wacky projects. This would make the time spent at work a lot more enjoyable and fun I think.",
    },
    {
      id: 4,
      text:
        "My ideal experience would look like forced time to play mid-way after working on a brief. Longer timelines to allow that. Field trips related to each brief.",
    },
    {
      id: 5,
      text:
        "Collaborations with external experts/contributors who join us as creative thinkers/problem solvers alongside us (subject matter experts/creators/artists).",
    },
    {
      id: 6,
      text:
        "An ideal experience would involve having a bunch of indoor games that people can play when they need to take a break from work. I would definitely love to play table tennis or chess or have some books that I can read and take my mind off work.",
    },
    {
      id: 7,
      text:
        "I wish there were group play times—times when I can interact with my coworkers with a non-work task. This task could be something that helps open up our minds to innovation, but isn't directly related to a work task... I think it would infuse creativity into my team and make us feel less like hamsters on wheels.",
    },
    {
      id: 8,
      text:
        "I would spend more time playing with data, more time on whiteboards with close collaborators, more permission to do weird and wacky projects. This would make the time spent at work a lot more enjoyable and fun I think.",
    },
    {
      id: 9,
      text:
        "My ideal experience would look like forced time to play mid-way after working on a brief. Longer timelines to allow that. Field trips related to each brief.",
    },
    {
      id: 10,
      text:
        "Collaborations with external experts/contributors who join us as creative thinkers/problem solvers alongside us (subject matter experts/creators/artists).",
    },
  ]
  return (
    <>
      <div className={`${styles.slider} ${styles.sliderLeft}`}>
        <div className={styles.slideTrack}>
          {firstSwiper.map((item, i) => (
            <div
              className={styles.slide}
              style={{ height: "auto" }}
              key={`marquee-${item.id}`}
            >
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`${styles.slider} ${styles.sliderRight} ${styles.sliderWider}`}
      >
        <div className={styles.slideTrack}>
          {secondSwiper.map((item, i) => (
            <div
              className={styles.slide}
              style={{ height: "auto" }}
              key={`marquee-${item.id}`}
            >
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
export default PurpleCarousel
