import React from "react"
import styles from "../rethinking.module.scss"

const GreenCarousel = () => {
  const firstSwiper = [
    {
      id: 1,
      text:
        "I get energized when I come up with an idea for execution, elicit help from others and they do their part to make it come to fruition.",
    },
    {
      id: 2,
      text:
        "Having that time and space allows you to dig in deep, to discuss different perspectives and to pull together new insights... But that's difficult to achieve if people are pulled in too many directions and have too many initiatives competing for their attention.",
    },
    {
      id: 3,
      text:
        "Coworkers who are passionate about what they do inspire me to meet their enthusiasm. My current team is like this and helps me be better at my role. In addition, it makes work more enjoyable.",
    },
    {
      id: 4,
      text:
        "One of the most stressful parts of my week is meeting with specific coworkers who I always feel like have high expectations and aren't approachable.",
    },
    {
      id: 5,
      text:
        "I get energized when I come up with an idea for execution, elicit help from others and they do their part to make it come to fruition.",
    },
    {
      id: 6,
      text:
        "Having that time and space allows you to dig in deep, to discuss different perspectives and to pull together new insights... But that's difficult to achieve if people are pulled in too many directions and have too many initiatives competing for their attention.",
    },
    {
      id: 7,
      text:
        "Coworkers who are passionate about what they do inspire me to meet their enthusiasm. My current team is like this and helps me be better at my role. In addition, it makes work more enjoyable.",
    },
    {
      id: 8,
      text:
        "One of the most stressful parts of my week is meeting with specific coworkers who I always feel like have high expectations and aren't approachable.",
    },
  ]
  const secondSwiper = [
    {
      id: 1,
      text:
        "What makes me feel defeated is lack of support from our leaders and not having our team be trusted. Expecting to give a hundred percent when you feel like the leaders are not keeping your best interests in mind.",
    },
    {
      id: 2,
      text:
        "When I realize that I'm not the only one on the team who doesn't know what the heck is going on. This makes me optimistic in that a) I'm not the only one not getting it, and b) I know we'll all work together to figure it out and it will be ok",
    },
    {
      id: 3,
      text:
        "That moment where you've been plugging away on a thing and then you turn to a coworker and show it to them... That then turns into a conversation where you feel recognized and affirmed and also inspired because they throw out some other ideas or help you think about it in a slightly different way. I love those moments.",
    },
    {
      id: 4,
      text:
        "I feel optimistic when people are open, direct and comfortable talking about potential vulnerabilities or weaknesses, because it gives me confidence that we can work through it and support each other.",
    },
    {
      id: 5,
      text:
        "What makes me feel defeated is lack of support from our leaders and not having our team be trusted. Expecting to give a hundred percent when you feel like the leaders are not keeping your best interests in mind.",
    },
    {
      id: 6,
      text:
        "When I realize that I'm not the only one on the team who doesn't know what the heck is going on. This makes me optimistic in that a) I'm not the only one not getting it, and b) I know we'll all work together to figure it out and it will be ok",
    },
    {
      id: 7,
      text:
        "That moment where you've been plugging away on a thing and then you turn to a coworker and show it to them... That then turns into a conversation where you feel recognized and affirmed and also inspired because they throw out some other ideas or help you think about it in a slightly different way. I love those moments.",
    },
    {
      id: 8,
      text:
        "I feel optimistic when people are open, direct and comfortable talking about potential vulnerabilities or weaknesses, because it gives me confidence that we can work through it and support each other.",
    },
  ]
  return (
    <>
      <div className={`${styles.slider} ${styles.sliderLeft}`}>
        <div className={styles.slideTrack}>
          {firstSwiper.map((item, i) => (
            <div
              className={styles.slide}
              style={{ height: "auto" }}
              key={`marquee-${item.id}`}
            >
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={`${styles.slider} ${styles.sliderRight}`}>
        <div className={styles.slideTrack}>
          {secondSwiper.map((item, i) => (
            <div
              className={styles.slide}
              style={{ height: "auto" }}
              key={`marquee-${item.id}`}
            >
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
export default GreenCarousel
