import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import styles from "./rethinking.module.scss"
import AnchorLink from "react-anchor-link-smooth-scroll"

import Image from "../../../components/image"

import { useInView } from "react-hook-inview"

import ScrollOut from "scroll-out"
import Contributors from "../../../components/athena/contributors"
import MoreArticles from "../../../components/athena/moreArticlesJoy"
import MarqueeText from "../../../components/marqueeText"
import GatedContent from "../../../components/gatedContent"

import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"

import Hero from "./article-06/hero"

import Increds from "../../../images/athena-04-resilience/06-rethinking/increds.gif"
import Polar from "../../../images/athena-04-resilience/06-rethinking/polarbear.gif"
import TheOffice from "../../../images/athena-04-resilience/06-rethinking/theoffice.gif"
import Peanuts from "../../../images/athena-04-resilience/06-rethinking/peanuts.gif"
import MadComp from "../../../images/athena-04-resilience/06-rethinking/madcomputer.gif"
import Hands from "../../../images/athena-04-resilience/06-rethinking/hands.gif"
import MeanGirls from "../../../images/athena-04-resilience/06-rethinking/meangirls.gif"
import Panda from "../../../images/athena-04-resilience/06-rethinking/panda.gif"
import AdventureTime from "../../../images/athena-04-resilience/06-rethinking/adventuretime.gif"

import SquareShape from "../../../images/athena-04-resilience/06-rethinking/squareshape.svg"
import NarrowSquare from "../../../images/athena-04-resilience/06-rethinking/narrowsquareshape.svg"
import {
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  FacebookShareButton,
  FacebookIcon,
} from "react-share"

import RedCarousel from "./article-06/redCarousel"
import GreeCarousel from "./article-06/greenCarousel"
import PurpleCarousel from "./article-06/purpleCarousel"
import BlueCarousel from "./article-06/blueCarousel"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
  headerPosition: styles.position,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  //const coverImages = article.coverImages ? article.coverImages[0] : null

  useEffect(() => {
    let so = ScrollOut({
      offset: 200,
      cssProps: {
        viewportY: true,
      },
    })
    return so.teardown
  }, [])

  const [sectionOneRef, sectionOneIsVisible] = useInView({
    threshold: 0,
  })
  const [sectionTwoRef, sectionTwoIsVisible] = useInView({
    threshold: 0.6,
  })
  const [sectionThreeRef, sectionThreeIsVisible] = useInView({
    threshold: 0.6,
  })
  const [sectionFourRef, sectionFourIsVisible] = useInView({
    threshold: 0.6,
  })

  const pinkSectionRef = useRef()
  const [pinkHeight, setPinkHeight] = useState(0)
  useEffect(() => {
    let resizeObserver
    if (pinkSectionRef.current) {
      const handleResize = () => {
        setPinkHeight(pinkSectionRef?.current?.clientHeight)
      }
      resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(pinkSectionRef.current)
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.unobserve(pinkSectionRef.current)
        resizeObserver.disconnect()
      }
    }
  }, [])
  const pinkSectionStyle = {
    backgroundColor: "#FFD6C9",
    position: "sticky",
    top: `calc(-${pinkHeight}px / 1.2)`,
  }

  const redSectionRef = useRef()
  const [redHeight, setRedHeight] = useState(0)
  useEffect(() => {
    let resizeObserver
    if (redSectionRef.current) {
      const handleResize = () => {
        setRedHeight(redSectionRef?.current?.clientHeight)
      }
      resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(redSectionRef.current)
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.unobserve(redSectionRef.current)
        resizeObserver.disconnect()
      }
    }
  }, [])
  const redSectionStyle = {
    backgroundColor: "#CD3909",
    position: "sticky",
    top: `calc(-${redHeight}px / 1.5)`,
  }

  const greenSectionRef = useRef()
  const [greenHeight, setGreenHeight] = useState(0)
  useEffect(() => {
    let resizeObserver
    if (greenSectionRef.current) {
      const handleResize = () => {
        setGreenHeight(greenSectionRef?.current?.clientHeight)
      }
      resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(greenSectionRef.current)
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.unobserve(greenSectionRef.current)
        resizeObserver.disconnect()
      }
    }
  }, [])
  const greenSectionStyle = {
    backgroundColor: "#277370",
    position: "sticky",
    top: `calc(-${greenHeight}px / 1.5)`,
  }

  const purpleSectionRef = useRef()
  const [purpleHeight, setPurpleHeight] = useState(0)
  useEffect(() => {
    let resizeObserver
    if (purpleSectionRef.current) {
      const handleResize = () => {
        setPurpleHeight(purpleSectionRef?.current?.clientHeight)
      }
      resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(purpleSectionRef.current)
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.unobserve(purpleSectionRef.current)
        resizeObserver.disconnect()
      }
    }
  }, [])
  const purpleSectionStyle = {
    backgroundColor: "#893A94",
    position: "sticky",
    top: `calc(-${purpleHeight}px / 1.5)`,
  }

  const blueberrySectionRef = useRef()
  const [blueberryHeight, setBlueberryHeight] = useState(0)
  useEffect(() => {
    let resizeObserver
    if (blueberrySectionRef.current) {
      const handleResize = () => {
        setBlueberryHeight(blueberrySectionRef?.current?.clientHeight)
      }
      resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(blueberrySectionRef.current)
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.unobserve(blueberrySectionRef.current)
        resizeObserver.disconnect()
      }
    }
  }, [])
  const blueberrySectionStyle = {
    backgroundColor: "#5254BC",
    position: "sticky",
    top: `calc(-${blueberryHeight}px / 1.5)`,
  }

  return (
    <Layout seo={seo} mode="athena" theme={theme}>
      <Helmet>
        <body className={`athena ${styles.body}`} />
      </Helmet>
      <article>
        <ScrollProgressBar />

        <div className={styles.article}>
          <Hero />

          <div
            className={styles.pinkSection}
            ref={pinkSectionRef}
            style={pinkSectionStyle}
          >
            <div className="wrapper">
              <div className={styles.readingTime}>
                <p className={styles.reading}>8 min reading time</p>
                <p className={styles.readingMobile}>8 min read</p>
                <div className={styles.socialContainer}>
                  <p className={styles.share}>Share —</p>
                  <TwitterShareButton
                    url={
                      "https://zeusjones.com/athena/articles/rethinking-resilience-at-work/"
                    }
                    title={article.title}
                    className={styles.shareButtons}
                    via="zeusjones"
                  >
                    <TwitterIcon />
                  </TwitterShareButton>

                  <LinkedinShareButton
                    url={
                      "https://zeusjones.com/athena/articles/rethinking-resilience-at-work/"
                    }
                    title={article.title}
                    className={styles.shareButtons}
                    via="zeusjones"
                  >
                    <LinkedinIcon />
                  </LinkedinShareButton>

                  <FacebookShareButton
                    url={
                      "https://zeusjones.com/athena/articles/rethinking-resilience-at-work/"
                    }
                    title={article.title}
                    className={styles.shareButtons}
                    via="zeusjones"
                  >
                    <FacebookIcon />
                  </FacebookShareButton>
                </div>
              </div>
              <div className="columns is-desktop">
                <div className="column is-10-tablet is-11-desktop">
                  <h2 className={`${styles.h2}`}>
                    I<span>n</span> re<span>c</span>ent years, <span>t</span>he
                    concept <em>o</em>f <em>r</em>esilien<span>c</span>e has
                    beco<span>m</span>e a buzz<span>w</span>ord in the w
                    <em>o</em>rkplace.
                  </h2>
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      Companies are ramping up investments in programs and
                      resources to help their employees develop resilience,
                      hoping to mitigate burnout and improve employee retention.
                      Yet our most celebrated acts of resilience - gritting our
                      teeth to perform under stress - rely on behaviors that put
                      our human needs last and erode our wellbeing. In the
                      relentless pursuit of resilience, are we compromising our
                      wellbeing?
                    </p>
                    <p>
                      It's a sobering question, made more urgent by a 2023
                      Gallup report revealing that{" "}
                      <a
                        className={styles.inlineLink}
                        href="https://www.gallup.com/workplace/468233/employee-engagement-needs-rebound-2023.aspx"
                        target="_blank"
                        rel="noreferrer"
                      >
                        a mere 32% of U.S. workers feel engaged at their
                        workplaces.
                      </a>{" "}
                      With this alarming trend of disengagement, there is a
                      pressing need to re-evaluate and re-imagine resilience at
                      the workplace.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.parallax}`} data-scroll="">
              <Image className={styles.parallaxBack} url={Increds} />
              <NarrowSquare className={styles.parallaxFrontDesktop} />
              <SquareShape className={styles.parallaxFrontMobile} />
            </div>
            <div>
              <a
                className={styles.caption}
                href="https://giphy.com/gifs/geek-the-incredibles-writing-schedule-13rQ7rrTrvZXlm"
                target="_blank"
                rel="noreferrer"
              >
                via Giphy
              </a>
            </div>
            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      We seek to turn the spotlight onto this dilemma, with the
                      aim to illuminate a more sustainable path forward.
                    </p>
                    <p>
                      What if we found ways to bring joy into work, rather than
                      separate it? What if our workplaces helped us navigate
                      what healthy collaboration, vulnerability, and risk-taking
                      might actually look like - rather than modeling unhealthy
                      competition and isolation?
                    </p>
                    <p>
                      Join us on this exploratory journey. Together, let's
                      disrupt conventional wisdom, challenge the status quo, and
                      forge a path towards a more fulfilling future of work.
                    </p>
                  </div>
                </div>
              </div>
              <div className="columns is-desktop">
                <div className="column is-10-tablet is-11-desktop">
                  <h2 className={`${styles.h2}`}>
                    <span>T</span>he <em>R</em>es<span>e</span>ar<span>c</span>h
                  </h2>
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      In January and February 2023 we conducted a study to
                      examine the behaviors that erode our wellbeing and explore
                      the policies, habits and rituals that could contribute to
                      a more sustainable, joyful resilience at work.
                    </p>
                    <p>
                      We spoke with 225 full-time, salaried workers to gain an
                      understanding of how they're feeling about their work -
                      what gives them energy, what drains it - and what a more
                      joyful future of work might look like. A third of the
                      respondents work in office settings, a third work fully
                      remote and a third are hybrid where they work at a blend
                      of in-office and remote. The range of respondents reflects
                      a breadth of workplace experiences.
                    </p>
                  </div>
                  <div className={styles.stats}>
                    <div className={styles.statsColumn}>
                      <h4>225</h4>
                      <p>
                        Full-time
                        <br />
                        salaried workers
                      </p>
                    </div>
                    <div className={styles.statsColumn}>
                      <h4>75</h4>
                      <p>In-person</p>
                    </div>
                    <div className={styles.statsColumn}>
                      <h4>75</h4>
                      <p>Fully remote</p>
                    </div>
                    <div className={styles.statsColumn}>
                      <h4>75</h4>
                      <p>Hybrid</p>
                    </div>
                  </div>
                  <p>Who they were:</p>
                </div>
              </div>
            </div>
            <div className={styles.headingScroller}>
              <div className={styles.list}>
                <ol>
                  <li>
                    <MarqueeText dir={1.5}>
                      <span className={`${styles.listItem}`}>
                        <em>R</em>es<span>e</span>ar<span>c</span>hers,
                      </span>
                      <span className={`${styles.listItem}`}>
                        Pr<em>o</em>ject Ma<span>n</span>agers,
                      </span>
                      <span className={`${styles.listItem}`}>
                        S<em>o</em>ftware Dev<span>e</span>lopers,
                      </span>
                      <span className={`${styles.listItem}`}>
                        Desig<span>n</span>ers,
                      </span>
                      <span className={`${styles.listItem}`}>
                        <span>E</span>nginee<span>r</span>s,
                      </span>
                      <span className={`${styles.listItem}`}>
                        Par<span>a</span>legals,
                      </span>
                      <span className={`${styles.listItem}`}>
                        Physi<span>c</span>ians,
                      </span>
                      <span className={`${styles.listItem}`}>
                        Interi<em>o</em>r Design<span>e</span>rs,
                      </span>
                    </MarqueeText>
                  </li>
                </ol>
              </div>
            </div>
            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      We also took a look at ourselves - turning the microscope
                      on our own behaviors and practices at Zeus Jones. Through
                      an internal survey we aimed to get a sense of where we are
                      living up to our ambitions to cultivate a more
                      sustainable, joyful resilience at work and where we are
                      not.
                    </p>
                    <p>
                      Scroll down to experience what we learned from this
                      research. At the end, you'll be able to &nbsp;
                      <AnchorLink
                        offset="100"
                        className={styles.inlineLink}
                        href="#form"
                      >
                        download the full summary of our findings.
                      </AnchorLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={styles.redSection}
            ref={redSectionRef}
            style={redSectionStyle}
          >
            <div className={`${styles.parallax}`} data-scroll="">
              <Image className={styles.parallaxBack} url={Polar} />
              <NarrowSquare className={styles.parallaxFrontDesktop} />
              <SquareShape className={styles.parallaxFrontMobile} />
            </div>
            <div>
              <a
                className={styles.caption}
                href="https://giphy.com/gifs/snow-school-453fdLGucOMwg"
                target="_blank"
                rel="noreferrer"
              >
                via Giphy
              </a>
            </div>
            <div className="wrapper">
              <p>We Heard:</p>
              <h2 className={styles.h2}>
                T<span>r</span>ust-starved empl<em>o</em>yees fa<span>c</span>e
                a<span>n</span> <span>u</span>phill b<span>a</span>ttle.
              </h2>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      When employees feel trusted to make their own decisions
                      about how and where they work, they are more likely to
                      feel a sense of ownership and pride. While trust from
                      leadership to act autonomously is crucial - isolation or
                      feeling siloed can be a significant source of stress.
                      Balancing autonomy and collectivity is key. When people
                      feel supported by their team, they are more likely to be
                      confident in their abilities to overcome challenges.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <RedCarousel />
          </div>

          <div
            className={styles.greenSection}
            ref={greenSectionRef}
            style={greenSectionStyle}
          >
            <div className={`${styles.parallax}`} data-scroll="">
              <Image className={styles.parallaxBack} url={TheOffice} />
              <NarrowSquare className={styles.parallaxFrontDesktop} />
              <SquareShape className={styles.parallaxFrontMobile} />
            </div>
            <div>
              <a
                className={styles.caption}
                href="https://giphy.com/gifs/the-office-michael-heartbreak-pynZagVcYxVUk"
                target="_blank"
                rel="noreferrer"
              >
                via Giphy
              </a>
            </div>
            {/* <div className={styles.sectionIntro} style={{backgroundImage: `url(${TheOffice})`}}>
              <SquareShape />
              <a className={styles.caption} href="https://giphy.com/gifs/the-office-michael-heartbreak-pynZagVcYxVUk" target="_blank" rel="noreferrer">via Giphy</a>
            </div> */}
            <div className="wrapper">
              <p>We Heard:</p>
              <h2 className={styles.h2}>
                <span>M</span>eani<span>n</span>gful <span>C</span>ollab
                <em>o</em>ration em<span>e</span>rges fr<em>o</em>m a
                <span>n</span> e<span>n</span>vironment <span>t</span>hat valu
                <span>e</span>s sh<span>a</span>red h<em>o</em>nesty and v
                <span>u</span>lner<span>a</span>bility.
              </h2>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      When given the time and space to work collaboratively with
                      a team, people are more likely to engage in deep thinking
                      and meaningful discussions that lead to new insights and
                      approaches. We want to collaborate with others - but these
                      moments can create additional stress and anxiety when
                      there's a lack of support or pressure to be perfect. The
                      best collaboration comes from moments of vulnerability and
                      radical honesty.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <GreeCarousel />
          </div>

          <div
            className={styles.purpleSection}
            ref={purpleSectionRef}
            style={purpleSectionStyle}
          >
            <div className={`${styles.parallax}`} data-scroll="">
              <Image className={styles.parallaxBack} url={Peanuts} />
              <NarrowSquare className={styles.parallaxFrontDesktop} />
              <SquareShape className={styles.parallaxFrontMobile} />
            </div>
            <div>
              <a
                className={styles.caption}
                href="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExcHE1Mm1iMTQyNWtyaG12bWNzNGp1MnA5dTdscTZyaHQwY2Z5cmo1biZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/157anh4ffDsSA/giphy.gif"
                target="_blank"
                rel="noreferrer"
              >
                via Giphy
              </a>
            </div>
            <div className="wrapper">
              <p>We Heard:</p>
              <h2 className={styles.h2}>
                <span>P</span>lay as a tool is <span>u</span>nd<span>e</span>
                rvalued and <em>o</em>ften see<span>n</span> as separate from
                the w<em>o</em>rk its<span>e</span>lf.
              </h2>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      Today, our notions of play are limited and often
                      disconnected from the actual nature of work. Many
                      organizations don't currently foster the necessary
                      conditions for play - not enough time, not enough support,
                      not enough models. When there's room to play and
                      experiment within workflows, it's possible to solve
                      complex and challenging problems.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <PurpleCarousel />
          </div>

          <div
            className={styles.blueberrySection}
            ref={blueberrySectionRef}
            style={blueberrySectionStyle}
          >
            <div className={`${styles.parallax}`} data-scroll="">
              <Image className={styles.parallaxBack} url={MadComp} />
              <NarrowSquare className={styles.parallaxFrontDesktop} />
              <SquareShape className={styles.parallaxFrontMobile} />
            </div>
            <div>
              <a
                className={styles.caption}
                href="https://giphy.com/gifs/page-shaft-crimson-IpkyqWqbipGg"
                target="_blank"
                rel="noreferrer"
              >
                via Giphy
              </a>
            </div>

            <div className="wrapper">
              <p>We Heard:</p>
              <h2 className={styles.h2}>
                Th<span>e</span> mo<span>n</span>otony <em>o</em>f r
                <span>e</span>mote wo<span>r</span>k <span>u</span>nd
                <span>e</span>rscores the <span>n</span>eed for n<em>o</em>vel{" "}
                <span>e</span>xperien<span>c</span>es.
              </h2>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      Working from home limits the range of experiences that
                      people have access to on a daily basis. There is also a
                      tension between the expectation to be constantly connected
                      online and an ability to engage with the world around us.
                      People want opportunities for both solo and shared
                      experiences that expose them to new ideas, perspectives,
                      and ways of thinking.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <BlueCarousel />
          </div>

          <div className={styles.blackSection}>
            <div className="wrapper">
              <h2 className={styles.h2}>
                <span>T</span>here ar<span>e</span> ma<span>n</span>y ways to
                emb<em>o</em>dy a h<span>e</span>althy, s<span>u</span>stain
                <span>a</span>ble <em>r</em>esili<span>e</span>n<span>c</span>e
                at w<em>o</em>rk:
              </h2>

              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      Maybe one day it's maximizing our autonomy to explore,
                      maybe another it's engaging in playful collaboration. But
                      the expectation of sacrificing our joy for productivity
                      still holds us back, and the models for a healthier
                      relationship with work are still very much in formation.
                      The responses of this survey show a need to
                      reconceptualize how trust, psychological safety, play, and
                      novelty become part of the work we do, not just sit
                      alongside it.
                    </p>
                  </div>
                  <h3 className={`${styles.h3} ${styles.margin}`}>
                    <span>We</span>
                    <span>
                      m<em>o</em>ve
                    </span>
                    <span>forward</span>
                    <span>by</span>
                    <span>
                      <span>e</span>xpandi<span>n</span>g
                    </span>
                    <span>what</span>
                    <span>
                      <span>w</span>e
                    </span>
                    <span>see</span>
                    <span>as</span>
                    <span>
                      pr<em>o</em>du<span>c</span>tive.
                    </span>
                  </h3>
                  <div className="user-content-athena">
                    <p>
                      This means understanding that some activities that may
                      seem unproductive in terms of output are actually critical
                      for unlocking our full productive energy. The best work
                      happens when people feel trusted, psychologically safe,
                      able to play, and are encouraged to explore. And when
                      people feel empowered to do their best work in an
                      environment that acknowledges these realities, they're
                      less likely to be drained by overcoming obstacles along
                      the way.
                    </p>
                    <p>
                      Taken together, this research shows four key ways to build
                      a workplace that reconciles productivity with a more
                      sustainable resilience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.stickyColumns}`}>
              <div className={styles.stickyColumnLeft}>
                <div
                  className={styles.svgWrapper}
                  style={{
                    backgroundImage: sectionOneIsVisible
                      ? `url(${Hands})`
                      : sectionTwoIsVisible
                      ? `url(${MeanGirls})`
                      : sectionThreeIsVisible
                      ? `url(${Panda})`
                      : sectionFourIsVisible
                      ? `url(${AdventureTime})`
                      : `url(${AdventureTime})`,
                  }}
                >
                  <SquareShape />
                </div>
                <a
                  className={styles.caption}
                  href={
                    sectionOneIsVisible
                      ? "https://giphy.com/gifs/new-girl-fox-new-girl-26xBAevSH9ORZM6tO"
                      : sectionTwoIsVisible
                      ? "https://giphy.com/gifs/paramountmovies-mean-girls-paramount-pictures-movies-PQ652dp4mVviXA3cvu"
                      : sectionThreeIsVisible
                      ? "https://giphy.com/gifs/panda-roll-Wji7dIi2rW7fy"
                      : "https://giphy.com/gifs/car-bad-tesla-daUOBsa1OztxC"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  via Giphy
                </a>
              </div>
              <div className={styles.stickyColumnRight}>
                <div className={styles.mobileGif}>
                  <div className={`${styles.parallax}`} data-scroll="">
                    <Image className={styles.parallaxBack} url={Hands} />
                    <NarrowSquare className={styles.parallaxFrontDesktop} />
                    <SquareShape className={styles.parallaxFrontMobile} />
                  </div>
                  <a
                    className={styles.caption}
                    href={
                      "https://giphy.com/gifs/new-girl-fox-new-girl-26xBAevSH9ORZM6tO"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    via Giphy
                  </a>
                </div>
                <div className={styles.stickyRow} ref={sectionOneRef}>
                  <span
                    className={styles.number}
                    style={{ backgroundColor: "#CD3909" }}
                  >
                    <h4>1</h4>
                  </span>
                  <h3 className={styles.h3}>
                    <span>
                      Ignit<span>e</span>
                    </span>
                    <span>
                      <span>c</span>ultures
                    </span>
                    <span>
                      <em>o</em>f
                    </span>
                    <span>
                      tr<span>u</span>st
                    </span>
                    <TwitterShareButton
                      url={
                        "https://zeusjones.com/athena/articles/rethinking-resilience-at-work"
                      }
                      title="How do we create a more sustainable, joyful resilience at work? By igniting cultures of trust that support both individual creativity and cross-team collaboration."
                      className={styles.shareButton}
                      via="zeusjones"
                    >
                      <span>
                        Tweet <TwitterIcon />
                      </span>
                    </TwitterShareButton>
                  </h3>
                  <p>
                    When employees are granted autonomy, they are empowered to
                    make decisions and take ownership of their work.
                    Collaboration, on the other hand, brings individuals
                    together to pool their knowledge, perspectives, and talents.
                    A culture of trust that supports both individual creativity
                    and cross-team collaboration ignites ownership and can
                    enhance innovation across the organization.
                  </p>
                </div>
                <div className={styles.mobileGif}>
                  <div className={`${styles.parallax}`} data-scroll="">
                    <Image className={styles.parallaxBack} url={MeanGirls} />
                    <NarrowSquare className={styles.parallaxFrontDesktop} />
                    <SquareShape className={styles.parallaxFrontMobile} />
                  </div>
                  <a
                    className={styles.caption}
                    href={
                      "https://giphy.com/gifs/paramountmovies-mean-girls-paramount-pictures-movies-PQ652dp4mVviXA3cvu"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    via Giphy
                  </a>
                </div>
                <div className={styles.stickyRow} ref={sectionTwoRef}>
                  <span
                    className={styles.number}
                    style={{ backgroundColor: "#277370" }}
                  >
                    <h4>2</h4>
                  </span>

                  <h3 className={styles.h3}>
                    <span>
                      <span>E</span>mbra<span>c</span>e
                    </span>
                    <span>
                      <em>R</em>adi<span>c</span>al
                    </span>
                    <span>
                      H<em>o</em>nes<span>t</span>y
                    </span>
                    <TwitterShareButton
                      url={
                        "https://zeusjones.com/athena/articles/rethinking-resilience-at-work"
                      }
                      title="How do we create a more sustainable, joyful resilience at work? By embracing radical honesty and setting an organizational foundation of psychological safety."
                      className={styles.shareButton}
                      via="zeusjones"
                    >
                      <span>
                        Tweet <TwitterIcon />
                      </span>
                    </TwitterShareButton>
                  </h3>

                  <p>
                    Genuine collaboration flourishes when individuals embrace
                    vulnerability and practice radical honesty. But it also
                    requires an organizational foundation of psychological
                    safety. When employees feel safe enough to take on uncertain
                    challenges and honestly express their thoughts, their
                    potential for growth and innovation is unlocked. Without
                    feeling safe to do so, someone may never voice their
                    breakthrough idea.
                  </p>
                </div>
                <div className={styles.mobileGif}>
                  <div className={`${styles.parallax}`} data-scroll="">
                    <Image className={styles.parallaxBack} url={Panda} />
                    <NarrowSquare className={styles.parallaxFrontDesktop} />
                    <SquareShape className={styles.parallaxFrontMobile} />
                  </div>
                  <a
                    className={styles.caption}
                    href={"https://giphy.com/gifs/panda-roll-Wji7dIi2rW7fy"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    via Giphy
                  </a>
                </div>
                <div className={styles.stickyRow} ref={sectionThreeRef}>
                  <span
                    className={styles.number}
                    style={{ backgroundColor: "#893A94" }}
                  >
                    <h4>3</h4>
                  </span>

                  <h3 className={styles.h3}>
                    <span>
                      <span>E</span>mpower
                    </span>
                    <span>
                      <span>P</span>lay
                    </span>
                    <span>
                      <span>a</span>s
                    </span>
                    <span>a</span>
                    <span>
                      <span>c</span>atalyst
                    </span>
                    <TwitterShareButton
                      url={
                        "https://zeusjones.com/athena/articles/rethinking-resilience-at-work"
                      }
                      title="How do we create a more sustainable, joyful resilience at work? By empowering play as a catalyst and integrating it into workflows as a tool."
                      className={styles.shareButton}
                      via="zeusjones"
                    >
                      <span>
                        Tweet <TwitterIcon />
                      </span>
                    </TwitterShareButton>
                  </h3>
                  <p>
                    Play encourages divergent thinking and imaginative
                    exploration, allowing individuals to break free from
                    conventional patterns and generate fresh ideas. But to
                    unlock play as an engine for creativity productivity we
                    can't be limited to ping pong tables and employer-sponsored
                    Zumba classes. Moving away from play as something that is
                    seen as separate from work to integrating playfulness into
                    workflows will allow organizations to realize the potential
                    of play as a tool for enhancing problem-solving skills and
                    unlocking innovation.
                  </p>
                </div>
                <div className={styles.mobileGif}>
                  <div className={`${styles.parallax}`} data-scroll="">
                    <Image
                      className={styles.parallaxBack}
                      url={AdventureTime}
                    />
                    <NarrowSquare className={styles.parallaxFrontDesktop} />
                    <SquareShape className={styles.parallaxFrontMobile} />
                  </div>
                  <a
                    className={styles.caption}
                    href={"https://giphy.com/gifs/car-bad-tesla-daUOBsa1OztxC"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    via Giphy
                  </a>
                </div>
                <div className={styles.stickyRow} ref={sectionFourRef}>
                  <span
                    className={styles.number}
                    style={{ backgroundColor: "#5254BC" }}
                  >
                    <h4>4</h4>
                  </span>
                  <h3 className={styles.h3}>
                    <span>
                      Pri<em>o</em>ritize
                    </span>
                    <span>
                      <span>N</span>ovel
                    </span>
                    <span>
                      <span>E</span>xperien<span>c</span>es
                    </span>
                    <TwitterShareButton
                      url={
                        "https://zeusjones.com/athena/articles/rethinking-resilience-at-work"
                      }
                      title="How do we create a more sustainable, joyful resilience at work? By prioritizing novel experiences and making time for places, cultures, art, or activities that inspire unconventional thinking."
                      className={styles.shareButton}
                      via="zeusjones"
                    >
                      <span>
                        Tweet <TwitterIcon />
                      </span>
                    </TwitterShareButton>
                  </h3>

                  <p>
                    People working from home crave a balance between online
                    connectivity and real-world experiences to fuel creativity.
                    Taking time to experience new environments, cultures, art
                    forms, or even leisure activities can inspire unconventional
                    thinking and imagination when solving work problems.
                    Promoting the productive value of breaking out of our
                    routines might seem counterintuitive, but breaking feelings
                    of isolation and the malaise of sameness day after day leads
                    to new perspectives and a work culture that innovates rather
                    than plods along.
                  </p>
                </div>
              </div>
            </div>
            <div id="form" className="wrapper">
              <div className="columns is-centered is-tablet">
                <div className="column is-10-tablet is-7-desktop">
                  <div className={styles.form}>
                    <div className="mb-2">
                      <h2
                        className={styles.formTitle}
                        style={{ textAlign: "center" }}
                      >
                        Fill out the form below to download the report featuring
                        in-depth insights and an actionable playbook.
                      </h2>
                    </div>
                    <GatedContent
                      uri={article.uri}
                      formId="bcd2ef1d-75ea-440a-9193-728ca2270b05"
                      defaultChecked="checked"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Contributors
                style={{ marginTop: "0" }}
                zeus={article.zeusContributors}
              />
              <MoreArticles
                className={styles.moreFrom}
                id={article.id}
                issue={article.issue[0].id}
              />
            </div>
            <PreFooter />
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
    }
  }
`
