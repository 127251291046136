import React from "react"
import styles from "../rethinking.module.scss"

const BlueCarousel = () => {
  const firstSwiper = [
    {
      id: 1,
      text:
        "Since I work from home, I have to sit at my desk all day. Sometimes I go hours without really doing anything.",
    },
    {
      id: 2,
      text: "I work from home, I'm very isolated and I'm home alone all day.",
    },
    {
      id: 3,
      text:
        "I like activities like work travel - it gives you new experiences and you get to be with people.",
    },
    {
      id: 4,
      text:
        "One thing that I've been missing in the remote environment is kinda like the cooler talk, the lunchroom talk with coworkers.",
    },
    {
      id: 5,
      text:
        "I really like the time to connect with coworkers, not just on our projects, but also just personally asking questions and connecting that way.",
    },
    {
      id: 6,
      text:
        "Our work relies on us being very online, and in my real life, I’m trying to be less online.",
    },
    {
      id: 7,
      text:
        "Since I work from home, I have to sit at my desk all day. Sometimes I go hours without really doing anything.",
    },
    {
      id: 8,
      text: "I work from home, I'm very isolated and I'm home alone all day.",
    },
    {
      id: 9,
      text:
        "I like activities like work travel - it gives you new experiences and you get to be with people.",
    },
    {
      id: 10,
      text:
        "One thing that I've been missing in the remote environment is kinda like the cooler talk, the lunchroom talk with coworkers.",
    },
    {
      id: 11,
      text:
        "I really like the time to connect with coworkers, not just on our projects, but also just personally asking questions and connecting that way.",
    },
    {
      id: 12,
      text:
        "Our work relies on us being very online, and in my real life, I’m trying to be less online.",
    },
  ]
  const secondSwiper = [
    {
      id: 1,
      text:
        "It's a hard balance feeling like I'm always on a device, when I want to be experiencing the world around me in a more natural manner.",
    },
    {
      id: 2,
      text:
        "I would like to have more cultural tours - leaving my house and exploring the world for the benefit of finding meaning linked to my projects ... ability to interact with the world at large vs just keynote and my computer.",
    },
    {
      id: 3,
      text:
        "I would like more dedicated days to prioritize play/being offline and reporting back what we learn, IRL workshops and client engagements that are offline and in fun environments, Less distractions, more physical things, more dreaming.",
    },
    {
      id: 4,
      text:
        "I think we would need some loose structures that create space to actually play, tinker, and share.",
    },
    {
      id: 5,
      text:
        "More time to learn new tools or try things out... not in service of a specific task/deadline at hand. This would actually open us up to more novel ways of working because we wouldn't have to reach for the tools and methods that we already know and are comfortable with.",
    },
    {
      id: 6,
      text:
        "It's a hard balance feeling like I'm always on a device, when I want to be experiencing the world around me in a more natural manner.",
    },
    {
      id: 7,
      text:
        "I would like to have more cultural tours - leaving my house and exploring the world for the benefit of finding meaning linked to my projects ... ability to interact with the world at large vs just keynote and my computer.",
    },
    {
      id: 8,
      text:
        "I would like more dedicated days to prioritize play/being offline and reporting back what we learn, IRL workshops and client engagements that are offline and in fun environments, Less distractions, more physical things, more dreaming.",
    },
    {
      id: 9,
      text:
        "I think we would need some loose structures that create space to actually play, tinker, and share.",
    },
    {
      id: 10,
      text:
        "More time to learn new tools or try things out... not in service of a specific task/deadline at hand. This would actually open us up to more novel ways of working because we wouldn't have to reach for the tools and methods that we already know and are comfortable with.",
    },
  ]

  return (
    <>
      <div
        className={`${styles.slider} ${styles.sliderLeft} ${styles.sliderWidest}`}
      >
        <div className={styles.slideTrack}>
          {firstSwiper.map((item, i) => (
            <div
              className={styles.slide}
              style={{ height: "auto" }}
              key={`marquee-${item.id}`}
            >
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`${styles.slider} ${styles.sliderRight} ${styles.sliderWider}`}
      >
        <div className={styles.slideTrack}>
          {secondSwiper.map((item, i) => (
            <div
              className={styles.slide}
              style={{ height: "auto" }}
              key={`marquee-${item.id}`}
            >
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
export default BlueCarousel
