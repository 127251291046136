//
// If this is used a third time, we should abstract this a little more so that the
// fields aren't all hard-coded. Is there a way to dynamically request the fields
// from HubSpot based on the formID? The more someone can manage this in HubSpot
// UI the better.
//
import React, { useReducer } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import InputText from "./inputText"
import InputCheckbox from "./inputCheckbox"
import styles from "./gatedContent.module.scss"
import submitHubSpotForm from "../services/submitHubSpotForm"

const GatedContent = ({ uri, formId, defaultChecked }) => {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "input":
          return {
            ...state,
            fields: {
              ...state.fields,
              [action.fieldName]: action.value,
            },
          }
        case "submitted":
          return {
            ...state,
            submitting: true,
            errorMessage: null,
            successMessage: null,
          }
        case "succeeded":
          return {
            ...state,
            submitting: false,
            successMessage:
              action.message ||
              "Thanks! Check your email for a link to the Workbook.",
          }
        case "failed":
          return {
            ...state,
            submitting: false,
            errorMessage: action.message || "Error. Try again later.",
          }
        default:
          return state
      }
    },
    {
      fields: {
        firstname: "",
        lastname: "",
        email: "",
        sign_up_for_our_newsletter: false,
      },
      submitting: false,
      successMessage: false,
      errorMessage: false,
    }
  )

  const handleSubmit = event => {
    event.preventDefault()
    dispatch({ type: "submitted" })

    submitHubSpotForm(formId, [
      {
        name: "firstname",
        value: state.fields.firstname,
      },
      {
        name: "lastname",
        value: state.fields.lastname,
      },
      {
        name: "email",
        value: state.fields.email,
      },
      {
        name: "sign_up_for_our_newsletter",
        value: state.fields.sign_up_for_our_newsletter,
      },
    ])
      .then(data => {
        // Track custom Google Analytics events
        trackCustomEvent({
          category: "Gated Content",
          action: "Form Submitted",
          label: uri,
          hitCallback: () => {
            dispatch({
              type: "succeeded",
              message: "Thanks! Check your email for a link to the workbook.",
            })
          },
        })
      })
      .catch(error => {
        const errorType = error.response.data.errors[0].errorType
        if (errorType === "INVALID_EMAIL") {
          dispatch({
            type: "failed",
            message:
              "That doesn’t look quite right. We'll need a valid email address.",
          })
        } else {
          dispatch({
            type: "failed",
            message:
              "Something went wrong. Sorry about that. Try again later or send us a message hello@zeusjones.com",
          })
        }
      })
  }

  return (
    <form action="#" method="post" onSubmit={handleSubmit}>
      <div className={styles.form}>
        <div className={styles.formInput}>
          <InputText
            label="First Name"
            name="firstname"
            id="firstname"
            type="text"
            placeholder=""
            required={true}
            block={true}
            onChange={value => {
              dispatch({ type: "input", fieldName: "firstname", value })
            }}
            value={state.fields.firstname}
          />
        </div>
        <div className={styles.formInput}>
          <InputText
            label="Last Name"
            name="lastname"
            id="lastname"
            type="text"
            placeholder=""
            required={true}
            block={true}
            onChange={value => {
              dispatch({ type: "input", fieldName: "lastname", value })
            }}
            value={state.fields.lastname}
          />
        </div>
        <div className={styles.formInput}>
          <InputText
            label="Email Address"
            name="email"
            id="email"
            type="email"
            placeholder=""
            required={true}
            block={true}
            onChange={value => {
              dispatch({ type: "input", fieldName: "email", value })
            }}
            value={state.fields.email}
          />
        </div>
        <div className={styles.formOptIn}>
          <InputCheckbox
            label="Sign up for our newsletter"
            name="sign_up_for_our_newsletter"
            id="sign_up_for_our_newsletter"
            defaultChecked={defaultChecked}
            onChange={event => {
              const value = event.target.checked
              dispatch({
                type: "input",
                fieldName: "sign_up_for_our_newsletter",
                value,
              })
            }}
            checked={state.fields.sign_up_for_our_newsletter}
          />
        </div>
        <div className={styles.formButton}>
          <button
            className="button button--small button--block"
            type="submit"
            disabled={state.submitting}
          >
            Submit
          </button>
        </div>
        <div className={styles.formResponse}>
          <div className="error">{state.errorMessage}</div>
          <div className={styles.formResponseSuccess}>
            {state.successMessage}
          </div>
        </div>
      </div>
    </form>
  )
}

export default GatedContent
